import { FC } from 'react';
import env from '../../../utils/env';
import Separator from '../Header/GeminiNavSeparator';
import GeminiSection from './GeminiSection';

const TitleEscrowNav: FC = () => {
  return (
    <div className='w-full xl:max-w-[1440px] mx-auto block xl:flex justify-between font-inter xl:px-[5%] bg-off-white overflow-y-auto'>
      <GeminiSection
        title='Title/Escrow'
        description=' One Real Title is a family of companies that uses proprietary
          technology to provide a better closing experience for real estate
          agents and their customers.'
      />
      <Separator />
      <GeminiSection
        links={[
          {
            title: 'Apply',
            description: 'Industry-leading technology simplify your closing.',
            href: `${env.NEXT_PUBLIC_ONEREAL_TITLE_APPLY_FORM_DOMAIN}`,
            target: '_blank',
          },
          {
            title: 'Services',
            description: 'Learn more about how we partner with agents.',
            href: `${env.NEXT_PUBLIC_TITLE_SUBDOMAIN}`,
            target: '_blank',
          },
        ]}
      />

      <Separator />

      <GeminiSection
        links={[
          {
            title: 'Contact Us',
            description: "We're here to help 24/7.",
            href: `${env.NEXT_PUBLIC_TITLE_SUBDOMAIN}/contact`,
            target: '_blank',
          },
          {
            title: 'Escrow (CA Residents)',
            description:
              'Simplify your transaction with transparency every step of the way.',
            href: `${env.NEXT_PUBLIC_TITLE_SUBDOMAIN}/escrow`,
            target: '_blank',
          },
        ]}
      />
    </div>
  );
};

export default TitleEscrowNav;
