/* eslint-disable @next/next/no-img-element */
import { FC } from 'react';
import env from '../../../utils/env';
import GeminiButton from '../GeminiButton';
import GeminiNavLinkButton from '../Header/GeminiNavLinkButton';
import Separator from '../Header/GeminiNavSeparator';
import GeminiSection from './GeminiSection';

const AgentsNav: FC = () => {
  return (
    <div className='w-full bg-off-white xl:overflow-hidden'>
      <div className='w-full xl:max-w-[1440px] mx-auto xl:py-[30px] xl:px-[5%]'>
        <h4 className='text-xs xl:text-base font-bold tracking-2.6 xl:tracking-[3.52px] text-cobalt xl:border-transparent border-b border-b-new-gray border-t border-t-new-gray px-5 xl:px-0 bg-light-secondary-gray-2 py-3 xl:py-0 xl:bg-off-white'>
          FOR AGENTS
        </h4>
        <h1 className='font-telegraf text-[26px] xl:text-4xl mt-[30px] mb-1.5 xl:my-2.5 px-5 xl:px-0'>
          Turn your thing into everything.
        </h1>

        <div className='flex flex-col xl:flex-row justify-between xl:items-end px-5 xl:px-0'>
          <p className='text-[15px] text-cobalt opacity-70 xl:w-1/2 mb-6 xl:mb-0'>
            The real estate industry is changing, and we&apos;re revolutionizing
            how you work, build wealth, and thrive. Turns out you can have it
            all.
          </p>

          <GeminiButton
            label='Agent Login'
            variant='primary-dark'
            href={`${env.NEXT_PUBLIC_BOLT_DOMAIN}/login`}
            target='_blank'
          />
        </div>
      </div>
      <div className='w-full h-[1px] bg-new-gray mt-[30px] xl:mt-0' />

      <div className='w-full h-full xl:max-w-[1440px] mx-auto block xl:flex justify-between font-inter xl:px-[5%] bg-off-white'>
        <div className='w-full xl:w-1/3 xl:py-[50px]'>
          <GeminiNavLinkButton
            title='Join Real'
            description='Where you want to go. Join our Real agent family.'
            titleClassName='text-[26px] xl:!text-4xl'
            hoverIconAlignment='right'
            href={`${env.NEXT_PUBLIC_JOINREAL_DOMAIN}`}
            containerClassName='!border-transparent'
          />

          <div className='xl:mt-[30px] py-5 xl:py-0 px-5 xl:px-0 border-b xl:border-transparent'>
            <img src='/img/real-group.jpg' alt='real-team' />
          </div>
        </div>

        <Separator />

        <GeminiSection
          subTitle='For Real Agents'
          links={[
            {
              title: 'Join Luxury',
              description:
                'Elevate your real estate career to new heights with Real Luxury.',
              href: `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/join-luxury`,
            },
            {
              title: 'Join Military Division',
              description:
                'Help improve the lives of military families through the dream of home ownership.',
              href: `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/military`,
            },
            {
              title: 'Events',
              description:
                "Increase your understanding and grow your sphere of influence with Real's virtual and in-person events.",
              href: `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/events`,
            },
          ]}
        />

        <Separator />

        <GeminiSection
          subTitle='Integrated Services'
          links={[
            {
              title: 'Become a Mortgage Advisor',
              description:
                'Learn more about how you can transform your financial future with One Real Mortgage.',
              href: `${env.NEXT_PUBLIC_MORTGAGE_SUBDOMAIN}/originate`,
              target: '_blank',
            },
            {
              title: 'Learn About One Real Title',
              description:
                'Take a deeper dive into the company guaranteeing maximum simplicity and transparency.',
              href: `${env.NEXT_PUBLIC_TITLE_SUBDOMAIN}`,
              target: '_blank',
            },
            {
              title: 'Learn About One Real Mortgage',
              description:
                "Get more info on company bringing trusted guidance to life's biggest financial decision.",
              href: `${env.NEXT_PUBLIC_MORTGAGE_SUBDOMAIN}`,
              target: '_blank',
            },
          ]}
        />
      </div>
    </div>
  );
};

export default AgentsNav;
