import React from 'react';
import env from '../../utils/env';
import LinkWithAnchor, { LinkWithAnchorProps } from '../LinkWithAnchor';

export enum FooterSectionTypeEnum {
  REAL_ESTATE = 'Real Estate',
  MORTGAGE = 'Mortgage',
  ABOUT_US = 'About Us',
  TITLE_ESCROW = 'Title / Escrow',
  DIVISIONS = 'Divisions',
  AGENTS = 'Agents',
}

interface FooterLink extends LinkWithAnchorProps {
  label: string;
}

const footerLinks: Record<FooterSectionTypeEnum, FooterLink[]> = {
  [FooterSectionTypeEnum.REAL_ESTATE]: [
    { label: 'Find an Agent', href: '/search-agent' },
    { label: 'Buy a Home', href: '/listings' },
    { label: 'Sell your Home', href: '/pages/sell' },
    {
      label: 'Military',
      href: `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/military`,
    },
    {
      label: 'Luxury',
      href: `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/luxury`,
    },
  ],
  [FooterSectionTypeEnum.MORTGAGE]: [
    {
      label: 'Purchase',
      href: `${env.NEXT_PUBLIC_MORTGAGE_SUBDOMAIN}/purchase`,
      target: '_blank',
    },
    {
      label: 'Refinance',
      href: `${env.NEXT_PUBLIC_MORTGAGE_SUBDOMAIN}/purchase`,
      target: '_blank',
    },
    {
      label: 'Current Programs',
      href: `${env.NEXT_PUBLIC_MORTGAGE_SUBDOMAIN}/current-programs`,
      target: '_blank',
    },
    {
      label: 'Apply Now',
      href: `${env.NEXT_PUBLIC_MYHOME_MORTGAGE_SUBDOMAIN}/homehub/signup/samir@lemonbrew.com?from_mobile_share=true`,
      target: '_blank',
    },
  ],
  [FooterSectionTypeEnum.ABOUT_US]: [
    {
      label: 'Our Story',
      href: '/pages/leadership',
    },
    {
      label: 'News + Awards',
      href: `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/newsroom`,
      target: '_blank',
    },
    {
      label: 'One Real Impact',
      href: `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/one-real-impact`,
      target: '_blank',
    },
    {
      label: 'Partners',
      href: `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/partners`,
      target: '_blank',
    },
    {
      label: 'Investors',
      href: `${env.NEXT_PUBLIC_INVESTORS_DOMAIN}`,
      target: '_blank',
    },
    {
      label: 'Careers',
      href: `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/careers`,
    },
    {
      label: 'Contact Us',
      href: '/pages/contact-us',
    },
  ],
  [FooterSectionTypeEnum.TITLE_ESCROW]: [
    {
      label: 'Title',
      href: `${env.NEXT_PUBLIC_TITLE_SUBDOMAIN}`,
    },
    {
      label: 'Submit an Order',
      href: `${env.NEXT_PUBLIC_ONEREAL_TITLE_APPLY_FORM_DOMAIN}`,
      target: '_blank',
    },
    {
      label: 'Escrow (CA Residents)',
      href: `${env.NEXT_PUBLIC_ONEREAL_ESCROW}`,
      target: '_blank',
    },
  ],
  [FooterSectionTypeEnum.DIVISIONS]: [
    {
      label: 'Luxury',
      href: `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/luxury`,
    },
    {
      label: 'Military',
      href: `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/military`,
    },
  ],
  [FooterSectionTypeEnum.AGENTS]: [
    {
      label: 'Agent Login',
      href: `${env.NEXT_PUBLIC_BOLT_DOMAIN}`,
    },
    {
      label: 'Events',
      href: `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/events`,
    },
    {
      label: 'Join Real',
      href: `${env.NEXT_PUBLIC_JOINREAL_DOMAIN}`,
    },
    {
      label: 'Join Luxury',
      href: `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/join-luxury`,
    },
    {
      label: 'Join Military',
      href: `${env.NEXT_PUBLIC_ONEREAL_DOMAIN}/pages/military`,
    },
  ],
};

interface GeminiFooterContentProps {
  type: FooterSectionTypeEnum;
}

const GeminiFooterContent: React.FC<GeminiFooterContentProps> = ({ type }) => {
  const links = footerLinks[type];

  return (
    <>
      <h5 className='font-telegraf font-light xl:text-2xl text-xl text-new-primary p-7 xl:pb-5 pb-0 xl:border-b border-new-gray border-opacity-40'>
        {type}
      </h5>
      <nav className='flex flex-col items-start xl:gap-[18px] gap-6 font-inter font-light text-[15px] leading-[30px] text-off-white p-7 py-14'>
        {links.map(({ label, href, target }) => (
          <LinkWithAnchor
            key={label}
            href={href ?? ''}
            target={target ?? '_self'}
          >
            {label}
          </LinkWithAnchor>
        ))}
      </nav>
    </>
  );
};

export default GeminiFooterContent;
