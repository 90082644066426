import React from 'react';
import { OfficesResponse } from '../openapi/bff';
import { LegaleseElement } from '../openapi/wanderer';
import {
  TypeFooter,
  TypeMarketsCountrySection,
} from '../types/generated/page-contents';
import GeminiFooterDisclaimer from './Gemini/GeminiFooterDisclaimer';
import GeminiFooterMiddle from './Gemini/GeminiFooterMiddle';
import GeminiFooterTop from './Gemini/GeminiFooterTop';
import GeminiNav from './Gemini/Header/GeminiNav';

interface LayoutRedesignedProps {
  children: any;
  countriesWithStates: TypeMarketsCountrySection[];
  disclaimers?: LegaleseElement[];
  footerContent: TypeFooter;
  allOffices: OfficesResponse;
}

const LayoutRedesigned: React.FC<LayoutRedesignedProps> = ({
  children,
  countriesWithStates,
  disclaimers,
  allOffices,
}) => {
  return (
    <div>
      <GeminiNav />
      {children}
      <div className='bg-cobalt'>
        <GeminiFooterTop />
        <GeminiFooterMiddle />
        <GeminiFooterDisclaimer
          disclaimers={disclaimers}
          brokerLicenses={[]}
          countriesWithStates={countriesWithStates}
          allOffices={allOffices}
        />
      </div>
    </div>
  );
};

export default LayoutRedesigned;
