/* eslint-disable @next/next/no-img-element */
import { FC } from 'react';
import env from '../../../utils/env';
import GeminiButton from '../GeminiButton';
import Separator from '../Header/GeminiNavSeparator';
import GeminiSection from './GeminiSection';

const MortgageNav: FC = () => {
  return (
    <div className='w-full xl:max-w-[1440px] mx-auto block xl:flex justify-between font-inter xl:px-[5%] bg-off-white overflow-y-auto'>
      <GeminiSection
        title='MORTGAGE'
        description='One Real Mortgage provides expert guidance and support, using top technology to maximize efficiency and minimize time to close.'
      />

      <Separator />

      <GeminiSection
        links={[
          {
            title: 'Purchase or Refinance',
            description:
              'Eliminate uncertainty with our 14-day clear to close guarantee.',
            href: `${env.NEXT_PUBLIC_MORTGAGE_SUBDOMAIN}/purchase`,
            target: '_blank',
          },
          {
            title: 'Apply',
            description: "Find the mortgage that's right for you",
            href: `${env.NEXT_PUBLIC_MYHOME_MORTGAGE_SUBDOMAIN}/homehub/signup/samir@lemonbrew.com?from_mobile_share=true`,
            target: '_blank',
          },
          {
            title: 'Contact Us',
            description: "We're here to help 24/7.",
            href: `${env.NEXT_PUBLIC_MORTGAGE_SUBDOMAIN}/contact-us`,
            target: '_blank',
          },
          {
            title: 'Current Programs',
            description: 'Exclusive promotions to save you time and money.',
            href: `${env.NEXT_PUBLIC_MORTGAGE_SUBDOMAIN}/current-programs`,
            target: '_blank',
          },
        ]}
      />

      <Separator />

      <div className='w-full xl:w-1/3 py-6 px-5 xl:py-[50px]'>
        <h4 className='font-bold tracking-2.6 text-cobalt text-xs'>
          FEATURED PROGRAM
        </h4>
        <h1 className='font-telegraf text-[26px] xl:text-4xl my-2.5 text-cobalt'>
          Save Up to $2,500*
        </h1>

        <p className='text-[15px] text-cobalt opacity-70 mb-2.5 xl:mb-5'>
          You may be eligible for up to $2,500 in closing costs from Real if you
          work with a Real agent and use One Real Mortgage to finance your home
          purchase.*
        </p>

        <GeminiButton
          label='Learn More'
          variant='primary-dark'
          href={`${env.NEXT_PUBLIC_MORTGAGE_SUBDOMAIN}/2500-at-closing`}
          target='_blank'
        />

        <div className='mt-[30px]'>
          <img
            src='/img/Mortgage-2500-program.jpg'
            alt='real-team'
            className='md:h-[200px] w-full object-cover'
          />
        </div>
      </div>
    </div>
  );
};

export default MortgageNav;
