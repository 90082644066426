import classNames from 'classnames';
import React from 'react';
import { isCASiteOnly } from '../../utils/SiteConfiguration';
import { realSocialLinks } from '../../utils/SocialLinkUtils';
import GeminiFooterContent, {
  FooterSectionTypeEnum,
} from './GeminiFooterContent';
import GeminiSocialLinks from './GeminiSocialLinks';

interface GeminiFooterMiddleProps {}

const GeminiFooterMiddle: React.FC<GeminiFooterMiddleProps> = () => {
  const isCanadaOnly = isCASiteOnly();

  return (
    <footer className='divide-y-2 mt-[60px] lg:mt-[90px] border-new-gray border-opacity-40 sm:border-t-0'>
      <div className='border-new-gray border-opacity-40 border-b xl:border-t xl:mx-0 lg:mx-[30px]'>
        {/* Desktop View */}
        <article
          className={classNames(
            'hidden xl:grid max-w-[1300px] mx-auto',
            isCanadaOnly
              ? 'xl:grid-cols-[3.5fr_2.5fr_2.5fr_2.5fr]'
              : 'xl:grid-cols-[6fr_3fr_3fr_3fr_3fr_3fr]'
          )}
        >
          <GeminiSocialLinks
            isCanadaOnly={isCanadaOnly}
            socials={realSocialLinks}
          />
          <section className='xl:border-r border-new-gray border-opacity-40 min-w-[200px]'>
            <GeminiFooterContent type={FooterSectionTypeEnum.REAL_ESTATE} />
          </section>
          {!isCanadaOnly && (
            <>
              <section className='xl:border-r border-new-gray border-opacity-40 min-w-[200px]'>
                <GeminiFooterContent type={FooterSectionTypeEnum.MORTGAGE} />
              </section>
              <section className='xl:border-r border-new-gray border-opacity-40 min-w-[200px]'>
                <GeminiFooterContent
                  type={FooterSectionTypeEnum.TITLE_ESCROW}
                />
              </section>
            </>
          )}
          <section className='xl:border-r border-new-gray border-opacity-40 min-w-[200px]'>
            <GeminiFooterContent
              type={
                isCanadaOnly
                  ? FooterSectionTypeEnum.DIVISIONS
                  : FooterSectionTypeEnum.AGENTS
              }
            />
          </section>
          <section className='xl:border-r border-new-gray border-opacity-40 min-w-[200px]'>
            <GeminiFooterContent type={FooterSectionTypeEnum.ABOUT_US} />
          </section>
        </article>

        {/* Mobile View */}
        <article className='xl:hidden grid grid-cols-1'>
          <GeminiSocialLinks
            isCanadaOnly={isCanadaOnly}
            socials={realSocialLinks}
          />
          <div className='grid grid-cols-2'>
            <div className='flex flex-col'>
              <section className='border-b border-new-gray border-opacity-40 border-r'>
                <GeminiFooterContent type={FooterSectionTypeEnum.REAL_ESTATE} />
              </section>
              <section className='border-new-gray border-opacity-40 border-r'>
                <GeminiFooterContent
                  type={
                    isCanadaOnly
                      ? FooterSectionTypeEnum.DIVISIONS
                      : FooterSectionTypeEnum.ABOUT_US
                  }
                />
              </section>
            </div>
            <div className='flex flex-col'>
              {!isCanadaOnly && (
                <>
                  <section className='border-b border-new-gray border-opacity-40'>
                    <GeminiFooterContent
                      type={FooterSectionTypeEnum.MORTGAGE}
                    />
                  </section>
                  <section className='border-b border-new-gray border-opacity-40'>
                    <GeminiFooterContent
                      type={FooterSectionTypeEnum.TITLE_ESCROW}
                    />
                  </section>
                </>
              )}
              <section>
                <GeminiFooterContent
                  type={
                    isCanadaOnly
                      ? FooterSectionTypeEnum.ABOUT_US
                      : FooterSectionTypeEnum.DIVISIONS
                  }
                />
              </section>
            </div>
          </div>
        </article>
      </div>
    </footer>
  );
};

export default GeminiFooterMiddle;
